import { PropsWithChildren } from "react";
import { useFeature } from "../../Hooks/useFeature";
import { FeatureContext } from "./FeatureContext";
import Loader from "../../Components/Loader";

export const FeatureProvider = ({ children }: PropsWithChildren) => {
  const {
    newQuoterFeature,
    squarePaymentMethodFeature,
    paypalPaymentMethodFeature,
    venmoPaymentMethodFeature,
    authorizePaymentMethodFeature,
    rewardsFeature,
    rewardsPopupFeature,
    isLoading,
    initialized,
    getAppFeatures,
    getUserFeature,
    getAllUserFeatures,
  } = useFeature();

  return (
    <FeatureContext.Provider
      value={{
        features: {
          newQuoterFeature,
          squarePaymentMethodFeature,
          paypalPaymentMethodFeature,
          venmoPaymentMethodFeature,
          authorizePaymentMethodFeature,
          rewardsFeature,
          rewardsPopupFeature,
        },
        getAppFeatures,
        getUserFeature,
        getAllUserFeatures,
      }}
    >
      {!isLoading && initialized ? children : <Loader full />}
    </FeatureContext.Provider>
  );
};
