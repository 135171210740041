export const NEW_QUOTER = "newQuoter";
export const SQUARE_PAYMENT_METHOD = "squarePaymentMethod";
export const PAYPAL_PAYMENT_METHOD = "paypalPaymentMethod";
export const VENMO_PAYMENT_METHOD = "venmoPaymentMethod";
export const AUTHORIZE_PAYMENT_METHOD = "authorizeNetPaymentMethod";
export const MANUAL_COUPON = "manualCoupon";
export const BESTFX_LESSFEE = "bestFxLessFee";

// Rewards
export const REWARDS_FEATURE = "rewards";
export const REWARDS_POPUP_FEATURE = "rewardsPopup";
