import { useSelector } from "react-redux";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { BeneficiarySelector } from "../../../Redux/Beneficiary/Beneficiary.slice";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { useTranslation } from "react-i18next";
import { ExchangeRateContainer } from "../QuoterCardAdvanced.styled";
import IconFont from "../../IconFont";
import { formatNumber } from "../../../Utilities/NumberUtils";
import { QuoteSummaryContainer } from "./QuoteSummary.styled";
import { Flags } from "../../Flags/Flags.component";
import { SenderExchangeRates } from "../../../Modules/Common/domain/ExchangeRates";
import { countrySelector } from "../../../Redux/Country/Country.slice";
import { useMemo } from "react";
import ZelleLogo from "../../../Assets/Img/zelle-rounded.png";
import SquareLogo from "../../../Assets/Img/square-rounded.png";
import PayPalLogo from "../../../Assets/Img/paypal-rounded.png";
import VenmoLogo from "../../../Assets/Img/venmo-rounded.png";
import SendolaPayLogo from "../../../Assets/Img/sendola-pay-short.png";
import AuthorizeLogo from "../../../Assets/Img/authorize-rounded.png";
import {
  PaymentMethod,
  PaymentMethods,
  PaymentMethodType,
  PlaidAccount,
  SquarePaymentMethod,
} from "../../../Modules/Common/domain/PaymentMethods";
import { maskInterbankKey } from "../../../Utilities/String";
import { ObjectFitImage } from "../../Image/Image.styled";
import theme from "../../../Contexts/ThemeProvider";
import { darkenColor } from "../../../Utilities/Colors";
import { PaymentMethodTypeTag } from "../PaymentMethodSelect/PaymentRadio/PaymentRadio.styled";
import { CouponBar } from "../../CouponBar/CouponBar.component";
import { PaymentOrderCreated } from "../../../Modules/PaymentOrders/domain/PaymentOrderCreated";
import { AlerBoxContainer } from "../../AlertBox/AlertBox.styled";
import { ZelleInstructions } from "./ZelleInstructions.component";
import { useModal } from "../../../Hooks/useModal";
import { calculateTotalBeforeDiscount, calculateTotalToShow } from "../../../Modules/Quote/domain/QuoteSelected";
import { VenmoInstructions } from "./VenmoInstructions.component";

interface QuoteSummaryProps {
  paymentOrderCreated?: PaymentOrderCreated;
}

export const QuoteSummary = ({ paymentOrderCreated }: QuoteSummaryProps) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const { modal, showModal } = useModal();

  const { countries } = useSelector(countrySelector);
  const {
    countryDestination,
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentMethod,
    currentPaymentDestination,
    paymentMethodSelected,
    couponToApply,
    currentQuoteDiscounted,
  } = useSelector(QuotationSelector).data;
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;

  const couponDiscountIsApplied = couponToApply || currentQuoteDiscounted;

  const totalCost = useMemo(
    () =>
      calculateTotalToShow({
        currentTotalCost:
          couponDiscountIsApplied && currentPaymentMethod?.discount
            ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
            : currentPaymentMethod?.totalCost,
        currentFee: currentDeliveryMethodQuote?.fee,
        currentQuoteDiscounted,
        discount: currentQuoteDiscounted?.discount,
        quoteDiscount: !couponDiscountIsApplied ? currentPaymentMethod?.discount : null,
      }),
    [currentPaymentMethod?.totalCost, currentDeliveryMethodQuote?.fee, currentQuoteDiscounted]
  );
  const { totalBeforePaymentMethodDiscount, totalDiscounts } = useMemo(
    () => calculateTotalBeforeDiscount(totalCost, currentPaymentMethod?.totalCost, currentPaymentMethod?.discount),
    [totalCost, currentQuoteDiscounted, currentPaymentMethod?.discount, currentPaymentMethod?.totalCost]
  );

  const isD2B = currentDeliveryMethodQuote?.deliveryMethodCode === "D2B";
  const isCPU = currentDeliveryMethodQuote?.deliveryMethodCode === "CPU";

  const paymentMethodData = useMemo(() => {
    if (paymentMethodSelected?.paymentSource) {
      const isSendolaPay = paymentMethodSelected?.paymentSource === "Sendola Card";
      const isPlaid = paymentMethodSelected?.paymentSource === "Sendola Plaid";
      const isZelle = paymentMethodSelected?.paymentSource === "Zelle";
      const isSquare = paymentMethodSelected?.paymentSource === "Square";
      return {
        name: getPaymentMethodName(paymentMethodSelected),
        type: (isSendolaPay || isPlaid
          ? "economy"
          : isZelle
          ? "economy-express-minutes"
          : isSquare
          ? "express"
          : "economy-minutes") as PaymentMethodType,
        logo: getLogoFromPaymentMethod(paymentMethodSelected.paymentSource),
        backgroundLogo: isPlaid
          ? (paymentMethodSelected as PlaidAccount)?.backgroundColor
          : isSendolaPay
          ? theme.White
          : undefined,
        gradientBackground: isPlaid ? darkenColor((paymentMethodSelected as PlaidAccount)?.backgroundColor) : undefined,
        logoWidth: isSendolaPay ? "40px" : "32px",
        logoHeight: isSendolaPay ? "40px" : "32px",
        logoPadding: isZelle ? "0px" : "5px",
      };
    }
  }, [paymentMethodSelected]);

  function getLogoFromPaymentMethod(paymentOrigin: PaymentMethods): string | undefined {
    switch (paymentOrigin) {
      case "Zelle":
        return ZelleLogo;
      case "Square":
        return SquareLogo;
      case "PayPal":
        return PayPalLogo;
      case "Venmo":
        return VenmoLogo;
      case "Sendola Plaid":
        return (paymentMethodSelected as PlaidAccount).logoUrl;
      case "Sendola Card":
        return SendolaPayLogo;
      case "authorize.net":
        return AuthorizeLogo;
      default:
        return undefined;
    }
  }

  function getPaymentMethodName(paymentMethod: PaymentMethod | PlaidAccount | SquarePaymentMethod) {
    switch (paymentMethod.paymentSource) {
      case "Sendola Plaid":
        return (paymentMethod as PlaidAccount).bankName;
      case "Sendola Card":
        return "ACH";
      case "authorize.net":
        return t("Authorize.summary");
      default:
        return paymentMethod.paymentSource;
    }
  }

  const openZelleInstructions = () => {
    let paymentOrder = {};

    if (paymentOrderCreated) {
      paymentOrder = {
        reference: paymentOrderCreated.mtcn,
        amount: paymentOrderCreated.payment.amount
          ? Number(paymentOrderCreated.payment.amount)
          : Number(paymentOrderCreated.instruction.origination.amount) +
            Number(paymentOrderCreated.instruction.quote.fee) +
            Number(paymentOrderCreated.instruction.quote.paymentMethodFee),
      };
    }

    showModal({
      modalType: "zelleInstructionsModal",
    });
  };

  const openVenmoInstructions = () => {
    let paymentOrder = {};

    if (paymentOrderCreated) {
      paymentOrder = {
        reference: paymentOrderCreated.mtcn,
        amount: paymentOrderCreated.payment.amount
          ? Number(paymentOrderCreated.payment.amount)
          : Number(paymentOrderCreated.instruction.origination.amount) +
            Number(paymentOrderCreated.instruction.quote.fee) +
            Number(paymentOrderCreated.instruction.quote.paymentMethodFee),
      };
    }

    showModal({
      modalType: "venmoInstructionsModal",
    });
  };

  return (
    <QuoteSummaryContainer direction="column" gap="16px">
      <FlexContainer direction="column" gap="4px">
        <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
          {tSendMoney("AmountToSend")}:
        </Text>
        <FlexContainer gap="12px" alignItems="start">
          <Flags>
            <img src={SenderExchangeRates[0].icon} />
            <img src={countries.find((c) => c.countryCode === countryDestination)?.icon} />
          </Flags>
          <FlexContainer direction="column">
            <Text size={1} weight={500} lineHeight="24px">
              {formatNumber(currentQuotation?.amount || 0, { minimumFractionDigits: 2 })}&nbsp;
              {currentQuotation?.originCurrency}
            </Text>
            <Text size={1} weight={500} lineHeight="24px" color="grey">
              {formatNumber(currentDeliveryMethodQuote?.amountToReceive || 0, { minimumFractionDigits: 2 })}&nbsp;
              {currentQuotation?.destinationCurrency}
            </Text>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <hr />
      <FlexContainer direction="column" gap="8px">
        <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
          {tSendMoney("RecipientTitle")}:
        </Text>
        <FlexContainer gap="12px">
          {isD2B && <IconFont name="building-bank" color="primaryStrong" weight={500} size="large" />}
          {isCPU && <IconFont name="hand-coin" color="primaryStrong" weight={500} size="large" />}
          <FlexContainer direction="column">
            <Text size={0.5} weight={500} lineHeight="24px">
              {beneficiarySelected?.fullName}
            </Text>
            <Text size={0.5} weight={500} lineHeight="14px" color="grey">
              {isD2B &&
                `${beneficiarySelected?.accountSelected?.bankName} ${maskInterbankKey(
                  beneficiarySelected?.accountSelected?.accountNumber || ""
                )}`}
              {isCPU && `${currentPaymentDestination?.destination}`}
            </Text>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <hr />
      <FlexContainer
        order={paymentOrderCreated && paymentMethodData?.name === "Zelle" ? -1 : undefined}
        direction="column"
        gap="26px"
      >
        <FlexContainer direction="column" gap="8px">
          <FlexContainer justify="space-between" alignItems="center">
            <Text size={1} lineHeight="24px" align="left" weight={600} margin={0}>
              {tSendMoney("PaymentMethod")}
            </Text>
            <PaymentMethodTypeTag type={paymentMethodData?.type}>
              {tSendMoney(`paymentMethods.types.${paymentMethodData?.type}`)}
            </PaymentMethodTypeTag>
          </FlexContainer>
          <FlexContainer gap="12px">
            <ObjectFitImage
              fit="contain"
              width={paymentMethodData?.logoWidth}
              height={paymentMethodData?.logoHeight}
              src={paymentMethodData?.logo}
              background={paymentMethodData?.backgroundLogo}
              gradientBackground={paymentMethodData?.gradientBackground}
              objectPosition="center"
              padding={paymentMethodData?.logoPadding}
              rounded
            />
            <FlexContainer direction="column" alignSelf="center">
              <Text size={0.5} weight={600} lineHeight="15px">
                {paymentMethodData?.name}
              </Text>
              {paymentOrderCreated && paymentMethodData?.name === "Zelle" ? (
                <ZelleInstructions
                  amount={paymentOrderCreated?.payment?.amount || totalCost}
                  reference={paymentOrderCreated?.mtcn || ""}
                />
              ) : (
                paymentMethodData?.name === "Zelle" && (
                  <FlexContainer alignItems="center" gap="11px" onClick={openZelleInstructions}>
                    <Text
                      weight={600}
                      size="default"
                      lineHeight="15px"
                      align="left"
                      color="zelle"
                      textDecoration="underline"
                    >
                      {t("Zelle.howToCta")}
                    </Text>
                    {modal}
                  </FlexContainer>
                )
              )}
              {paymentOrderCreated && paymentMethodData?.name === "Venmo" ? (
                <VenmoInstructions
                  amount={paymentOrderCreated?.payment?.amount || totalCost}
                  reference={paymentOrderCreated?.mtcn || ""}
                />
              ) : (
                paymentMethodData?.name === "Venmo" && (
                  <FlexContainer alignItems="center" gap="11px" onClick={openVenmoInstructions}>
                    <Text
                      weight={600}
                      size="default"
                      lineHeight="15px"
                      align="left"
                      color="venmo"
                      textDecoration="underline"
                    >
                      {t("Venmo.howToCta")}
                    </Text>
                    {modal}
                  </FlexContainer>
                )
              )}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <hr />
      </FlexContainer>
      <FlexContainer alignItems="baseline" justify="space-between" p="4px 8px 4px 8px" as={ExchangeRateContainer}>
        <Text size={0.5} lineHeight="21px" align="left" weight={600} margin={0}>
          {tSendMoney("ExchangeRate")}
        </Text>
        <Text size={0.5} lineHeight="21px" align="right" weight={600} margin={0}>
          $1.00 USD = ${formatNumber(currentDeliveryMethodQuote?.fx || 0, { minimumFractionDigits: 2 })}{" "}
          {currentQuotation?.destinationCurrency}
        </Text>
      </FlexContainer>
      {(paymentMethodSelected?.paymentSource === "Zelle" || paymentMethodSelected?.paymentSource === "Venmo") && (
        <FlexContainer m="-8px 0 0 0">
          <AlerBoxContainer>
            <FlexContainer justify="center" alignItems="center" gap="8px">
              <IconFont name="alert" color="warningStrong" weight={500} />
              <Text align="left" size="default" lineHeight="14px" weight={500} color="warningStrong">
                {paymentMethodSelected?.paymentSource === "Zelle"
                  ? t("Zelle.expirationDisclaimer")
                  : t("Venmo.expirationDisclaimer")}
              </Text>
            </FlexContainer>
          </AlerBoxContainer>
        </FlexContainer>
      )}
      <FlexContainer direction="column" gap="8px">
        <FlexContainer alignItems="baseline" justify="space-between">
          <Text size={0.5} lineHeight="21px" weight={500} color="grey" margin={0}>
            {tSendMoney("TransferFees")}:
          </Text>
          {currentQuoteDiscounted && currentQuoteDiscounted.fee !== currentDeliveryMethodQuote?.fee ? (
            <FlexContainer w="max-content" alignItems="center" gap="6px">
              <Text size={0.5} lineHeight="21px" weight={500} color="solid_2" textDecoration="line-through">
                $
                {formatNumber(currentDeliveryMethodQuote?.fee || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
              <Text size={0.5} lineHeight="21px" weight={500}>
                $
                {formatNumber(currentQuoteDiscounted?.fee || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            </FlexContainer>
          ) : (
            <Text size={0.5} lineHeight="21px" weight={500}>
              $
              {formatNumber(currentDeliveryMethodQuote?.fee || 0, {
                minimumFractionDigits: 2,
              })}{" "}
              USD
            </Text>
          )}
        </FlexContainer>
        {currentPaymentMethod && (
          <FlexContainer alignItems="baseline" justify="space-between">
            <Text size={0.5} lineHeight="21px" weight={500} color="grey" margin={0}>
              {tSendMoney("PaymentMethodFee")}:
            </Text>
            {currentQuoteDiscounted &&
            currentQuoteDiscounted.paymentMethodFee !== currentPaymentMethod.paymentMethodFee ? (
              <FlexContainer w="max-content" alignItems="center" gap="6px">
                <Text size={0.5} lineHeight="21px" weight={500} color="solid_2" textDecoration="line-through">
                  $
                  {formatNumber(currentPaymentMethod.paymentMethodFee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
                <Text size={0.5} lineHeight="21px" weight={500}>
                  $
                  {formatNumber(currentQuoteDiscounted?.paymentMethodFee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
              </FlexContainer>
            ) : (
              <Text size={0.5} lineHeight="21px" weight={500} margin={0}>
                $
                {formatNumber(currentPaymentMethod.paymentMethodFee || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            )}
          </FlexContainer>
        )}
        {!couponDiscountIsApplied && currentPaymentMethod?.discount && (
          <FlexContainer alignItems="baseline" justify="space-between">
            <Text size={0.5} lineHeight="21px" weight={500} color="grey" margin={0} gradient>
              {tSendMoney("ZelleDiscount")}:
            </Text>
            <Text size={0.5} lineHeight="21px" weight={500} margin={0} gradient>
              - $
              {formatNumber(currentPaymentMethod.discount || 0, {
                minimumFractionDigits: 2,
              })}{" "}
              USD
            </Text>
          </FlexContainer>
        )}
      </FlexContainer>
      <hr />
      <FlexContainer justify="space-between">
        <Text size={0.5} lineHeight="21px" weight={700} color="grey" margin={0}>
          {tSendMoney("TotalPayment")}:
        </Text>
        {currentQuoteDiscounted?.discount && totalCost !== currentPaymentMethod?.totalCost ? (
          <FlexContainer w="auto" direction="column" alignItems="end">
            <FlexContainer w="max-content" alignItems="center" gap="6px">
              <Text size={0.5} lineHeight="21px" weight={500} color="neutral600" textDecoration="line-through">
                $
                {formatNumber(currentPaymentMethod?.totalCost || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
              </Text>
              <Text size={0.5} lineHeight="21px" weight={500} gradient>
                $
                {formatNumber(totalCost || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            </FlexContainer>
            <Text as="span" size={0.5} weight={600} lineHeight="21px">
              {tSendMoney("Coupons.save", {
                amount: formatNumber(currentQuoteDiscounted?.discount || 0, {
                  minimumFractionDigits: 2,
                }),
              })}
            </Text>
          </FlexContainer>
        ) : !couponDiscountIsApplied &&
          currentPaymentMethod?.discount &&
          totalBeforePaymentMethodDiscount !== totalCost ? (
          <FlexContainer w="auto" direction="column" alignItems="end">
            <FlexContainer w="max-content" alignItems="center" gap="6px">
              <Text size={0.5} lineHeight="21px" weight={500} color="neutral600" textDecoration="line-through">
                $
                {formatNumber(totalBeforePaymentMethodDiscount || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
              </Text>
              <Text size={0.5} lineHeight="21px" weight={500} gradient>
                $
                {formatNumber(totalCost || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            </FlexContainer>
            <Text as="span" size={0.5} weight={600} lineHeight="21px">
              {tSendMoney("Coupons.save", {
                amount: formatNumber(totalDiscounts || 0, {
                  minimumFractionDigits: 2,
                }),
              })}
            </Text>
          </FlexContainer>
        ) : (
          <Text size={0.5} lineHeight="21px" weight={500} color="magenta" margin={0}>
            $
            {formatNumber(totalCost, {
              minimumFractionDigits: 2,
            })}{" "}
            USD
          </Text>
        )}
      </FlexContainer>
      {couponToApply && <CouponBar couponCode={couponToApply.code || "REF-FRIENDS"} />}
    </QuoteSummaryContainer>
  );
};
