import { PropsWithChildren, ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Components
import Text from "../../../Text";
import IconFont from "../../../IconFont";
import { Superscript } from "../../../Text/Superscript.component";
import IconShape from "../../../IconShape";
import RadioCustom from "../../../RadioCustom";
import { FlexContainer } from "../../../Flex/Flex.styled";
import { GradientCard } from "../../../Card/Card.styled";

// Assets
import VisaLogo from "../../../../Assets/Img/visa.png";
import MasterCardLogo from "../../../../Assets/Img/mastercard.png";
import ApplePayLogo from "../../../../Assets/Img/apple-pay.png";
import GPayLogo from "../../../../Assets/Img/google-pay.png";
import AmexLogo from "../../../../Assets/Img/amex-minified.png";
import PlaidLogos from "../../../../Assets/Img/plaid-banks-logos.png";

import { PaymentMethod, PaymentMethodType, PlaidAccount } from "../../../../Modules/Common/domain/PaymentMethods";
import { QuotationSelector } from "../../../../Redux/Quotation/Quotation.slice";

import { AccountLogo, ObjectFitImage } from "../../../Image/Image.styled";
import { darkenColor } from "../../../../Utilities/Colors";

import {
  BankSupportedLogoContainer,
  MostPopularTag,
  PaymentMethodCard,
  PaymentMethodRadioContainer,
  PaymentMethodTypeTag,
  PlaidLogoElement,
  SendolaPayAccountLogos,
  SendolaPayLogoElement,
} from "./PaymentRadio.styled";

interface PaymentRadioProps extends PropsWithChildren {
  type: PaymentMethodType;
  logo?: string;
  logoElement?: ReactNode;
  value: string;
  title?: string;
  description?: string;
  showBankSupported?: boolean;
  banksSupportedLogos?: string[];
  checked?: boolean;
  balance?: number;
  mostPopular?: boolean;
  discount?: number | null;
  forwardRef?: React.RefCallback<HTMLInputElement>;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

export const PaymentRadio = ({
  type,
  logo,
  logoElement,
  value,
  title,
  description,
  showBankSupported,
  banksSupportedLogos = [VisaLogo, MasterCardLogo, ApplePayLogo, AmexLogo, GPayLogo],
  checked,
  children,
  mostPopular,
  discount,
  forwardRef,
  onChange,
  onClick,
}: PaymentRadioProps) => {
  const [tSendMoney] = useTranslation("sendMoney");
  const radioRef = useRef<HTMLInputElement | null>(null);

  return (
    <PaymentMethodRadioContainer
      alignItems="center"
      gap="8px"
      onClick={() => {
        if (radioRef?.current) {
          radioRef.current.click();
        }
        onClick && onClick();
      }}
    >
      <FlexContainer w="20px">
        <RadioCustom
          forwardRef={(el) => {
            if (radioRef) {
              radioRef.current = el;
            }
            if (forwardRef) {
              forwardRef(el);
            }
          }}
          name="paymentMethod"
          value={value}
          checked={checked}
          onChange={(e) => onChange && onChange(e)}
        />
      </FlexContainer>

      <FlexContainer direction="column" gap="4.5px">
        <PaymentMethodTypeTag type={type} margin={discount ? "0 0 0.5rem 0" : undefined}>
          {tSendMoney(`paymentMethods.types.${type}`)}
          <Superscript note="7" />
        </PaymentMethodTypeTag>
        <GradientCard
          borderWidth="2px"
          width="100%"
          padding="15px"
          borderColor={mostPopular ? "primaryStrong" : "muted"}
        >
          {mostPopular && (
            <MostPopularTag gradient={!!discount}>
              {discount && `${tSendMoney("AmountOFF", { amount: discount })} - `}
              {tSendMoney("paymentMethods.mostPopular")}
            </MostPopularTag>
          )}
          <PaymentMethodCard>
            {!logoElement ? <img src={logo} /> : logoElement}
            <FlexContainer direction="column" gap="4px">
              {showBankSupported ? (
                <>
                  <FlexContainer flex="1 1 auto" justify="start" alignItems="start" w="100%" flexWrap>
                    {banksSupportedLogos.map((bankLogo, idx) => (
                      <BankSupportedLogoContainer key={`bankLogo-${value}-${idx}`}>
                        <img src={bankLogo} height="100%" />
                      </BankSupportedLogoContainer>
                    ))}
                  </FlexContainer>
                  <Text align="left" color="grey" size="default" weight={400}>
                    {description}
                  </Text>
                </>
              ) : (
                <>
                  {title && (
                    <Text align="left" color="black" size="default">
                      <span dangerouslySetInnerHTML={{ __html: title }}></span>
                    </Text>
                  )}
                  {description && (
                    <Text align="left" color="grey" size="default" weight={400}>
                      <span dangerouslySetInnerHTML={{ __html: description }}></span>
                    </Text>
                  )}
                </>
              )}
              {children}
            </FlexContainer>
          </PaymentMethodCard>
        </GradientCard>
      </FlexContainer>
    </PaymentMethodRadioContainer>
  );
};

export const PlaidPaymentRadio = (props: PaymentRadioProps) => {
  return (
    <PaymentRadio
      {...props}
      description={undefined}
      logoElement={
        <PlaidLogoElement>
          <IconFont name="external-link" color="white" size="default" />
        </PlaidLogoElement>
      }
    >
      {props.description && (
        <Text align="left" color="grey" size="default" weight={400} as="div">
          <span
            style={{ display: "inline" }}
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          ></span>
          <FlexContainer justify="end">
            <img src={PlaidLogos} width={109} />
          </FlexContainer>
        </Text>
      )}
    </PaymentRadio>
  );
};

export const SendolaPaymentRadio = (props: PaymentRadioProps) => {
  const [tSendMoney] = useTranslation("sendMoney");
  const { paymentMethods } = useSelector(QuotationSelector).data;

  const plaidAccounts = paymentMethods?.plaid?.accounts?.filter((acc) => acc.isVisible) || [];
  const sendolaAccounts = paymentMethods?.sendolaPay?.filter((acc) => acc.isVisible) || [];

  const plaidAccountsPair = plaidAccounts.slice(0, 1);
  const sendolaAccountsPair = sendolaAccounts.slice(0, 2);

  const accounts: Array<PlaidAccount | PaymentMethod> = [...sendolaAccountsPair, ...plaidAccountsPair];

  return (
    <PaymentRadio
      {...props}
      title={undefined}
      description={undefined}
      logoElement={
        <SendolaPayLogoElement>
          <IconShape icon="ach" iconSize="medium" height={40} width={40} border="rounded" gradient />
        </SendolaPayLogoElement>
      }
    >
      <FlexContainer alignItems="center">
        <FlexContainer direction="column" flex="1 1 auto" w="auto" p="0 8px 0 0">
          <Text align="left" color="black" size="default">
            <span
              dangerouslySetInnerHTML={{
                __html: tSendMoney("paymentMethods.sendolaPay.title"),
              }}
            ></span>
          </Text>
          <Text align="left" color="grey" size="default" weight={400}>
            <span
              dangerouslySetInnerHTML={{
                __html: tSendMoney("paymentMethods.sendolaPay.description"),
              }}
            ></span>
          </Text>
        </FlexContainer>
        <SendolaPayAccountLogos>
          {accounts.length > 0 &&
            accounts.map((account) => (
              <AccountLogo
                key={account.id}
                backgroundColor={
                  account.paymentSource === "Sendola Plaid"
                    ? (account as PlaidAccount).backgroundColor
                    : (account as PaymentMethod).backgroundBank
                }
                gradientBackgroundColor={darkenColor(
                  account.paymentSource === "Sendola Plaid"
                    ? (account as PlaidAccount).backgroundColor
                    : (account as PaymentMethod).backgroundBank
                )}
              >
                <ObjectFitImage
                  fit="contain"
                  width="24px"
                  height="24px"
                  src={
                    account.paymentSource === "Sendola Plaid"
                      ? (account as PlaidAccount).logoUrl
                      : (account as PaymentMethod).logoUrlBank
                  }
                />
              </AccountLogo>
            ))}
        </SendolaPayAccountLogos>
      </FlexContainer>
    </PaymentRadio>
  );
};

export const AuthorizePaymentRadio = (props: PaymentRadioProps) => {
  const [tSendMoney] = useTranslation("sendMoney");

  return (
    <PaymentRadio {...props} title={undefined} description={undefined} logoElement={<img src={props.logo} />}>
      <FlexContainer>
        <FlexContainer direction="column" flex="1 1 auto" w="auto" p="0 8px 0 0">
          <Text align="left" color="black" size="default">
            <span
              dangerouslySetInnerHTML={{
                __html: props.title as string,
              }}
            ></span>
          </Text>
          <Text align="left" color="grey" size="default" weight={400}>
            <span
              dangerouslySetInnerHTML={{
                __html: props.description as string,
              }}
            ></span>
          </Text>
          <FlexContainer flex="1 1 auto" justify="start" alignItems="start" m="5px 0 0 0" w="100%">
            {[VisaLogo, MasterCardLogo, AmexLogo].map((bankLogo, idx) => (
              <BankSupportedLogoContainer key={`bankLogo-${props.value}-${idx}`}>
                <img src={bankLogo} height="100%" />
              </BankSupportedLogoContainer>
            ))}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </PaymentRadio>
  );
};
